/*eslint-disable*/
export default class MobileMenu {
	constructor(menuToggleElements) {
		this.menuToggle = menuToggleElements;
	}

	clickListener() {
		const menuItems = document.querySelectorAll('.resp-menu .menu-item');
		menuItems.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				const body = document.querySelector('body');
				body.classList.toggle('menu-open');
			});
		});
	}

	initializeListeners() {
		this.menuToggle.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				const body = document.querySelector('body');

				document.querySelector('.menu-toggle.js-menu-toggle').classList.toggle('menu-toggle--active');
				body.classList.toggle('menu-open');
			});
		});
	}
}
