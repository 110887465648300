/*eslint-disable*/
// core version + navigation, pagination modules:
import Swiper, { Navigation, Autoplay, EffectFade, loop } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

export default function heroSlider() {
	// init Swiper:
	const swiper = new Swiper('.hero__slider', {
		//Speed
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		speed: 1000,
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		loop: true,
		modules: [Navigation, Autoplay, EffectFade],
		slidesPerView: 3,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
}
