/*eslint-disable*/
/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import heroSlider from './components/heroSlider';
import MobileMenu from './components/mobile-menu';
/**
 * Components
 */
const heroSliderElement = document.querySelector('.hero__slider');

document.addEventListener('DOMContentLoaded', () => {
    svg4everybody();
    if (heroSliderElement) {
        heroSlider();
    }

    const mobileMenu = new MobileMenu(document.querySelectorAll('.js-menu-toggle'));
    // const stickyHeader = new StickyHeader();

    /**
     * Mobile menu functionalities
     */
    mobileMenu.initializeListeners();
    mobileMenu.clickListener();

    /**
     * Sticky header functionalities
     */
    // stickyHeader.initializeListeners(stickyHeader.refreshStickyHeader);
});
